import React from 'react'

import Hospitals from '../AdminComponents/Hospitals'

const HospitalPages = () => {
  return (
    <div>
        <Hospitals />
    </div>
  )
}

export default HospitalPages