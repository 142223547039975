import React from 'react'
import AdminLogin from '../AdminComponents/AdminLogin'

const AdminLoginPage = () => {
  return (
    <div>
        <AdminLogin />
    </div>
  )
}

export default AdminLoginPage