import React from 'react'
import HospitalRequests from '../AdminComponents/HospitalRequests'

const HospitalRequestsPage = () => {
  return (
    <div>
        <HospitalRequests />
    </div>
  )
}

export default HospitalRequestsPage