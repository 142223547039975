import React from 'react'
import AdminPanel from '../AdminComponents/AdminPanel'
import Patients from '../AdminComponents/Patients'

const PatientPage = () => {
  return (
    <div>
        <Patients/>
    </div>
  )
}

export default PatientPage