import React from 'react'
import Navbar from './Navbar/Navbar'
import Home from './Home/Home'
import Hospital from './Home/Hospital'






const HomePage = () => {
  return (
    <div>
        <Navbar/>
        <Home />
        
        
        <Hospital/>
        
        
    </div>
  )
}

export default HomePage